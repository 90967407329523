@use "../../styles/colors";

.myAccount {
  position: relative;
  cursor: pointer;
  align-items: center;
}

.menu {
  position: absolute;
  right: -1px;
  margin-top: 8px;
  padding: 16px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
  overflow: visible;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;

    li {
      margin-bottom: 16px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}