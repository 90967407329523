@use "./colors";

@font-face {
  font-family: "Averta";
  src: url("../fonts/Averta-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("../fonts/Averta-RegularItalic.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Averta";
  src: url("../fonts/Averta-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("../fonts/Averta-BoldItalic.otf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Averta";
  src: url("../fonts/Averta-Semibold.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("../fonts/Averta-SemiboldItalic.otf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 32px;
  font-weight: 400;
}

h2 {
  font-size: 26px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.app {
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow-x: hidden;

  .titlePlusContentContainer {
    display: flex;
    flex-direction: column;
    padding: 24px 32px;

    .contentContainer {
      display: flex;
      flex-direction: row;
    }

    &:not(.scroll) {
      flex: 1 1 auto;
    }

    &.scroll {
      .contentContainer {
        overflow-y: auto;
      }
    }
  }
}

.hidden {
  display: none !important;
}