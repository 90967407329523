@use "../../styles/colors";

.signinApp {
  padding: 16px;
  height: 100vh;
  background-color: colors.$grey1;

  h1 {
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 8px;
  }

  a {
    font-family: "Averta", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .signinContainer {
    display: flex;
    flex-direction: row;

    .card {
      min-width: 400px;
      max-width: 600px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;

      .signInButton {
        margin-top: auto;
      }
    }
  }
}