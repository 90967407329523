@use "../../styles/colors";

.menuApp {
  a {
    font-family: "Averta", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    text-decoration: none;
    color: #0a7c8e;
  }

  a[href^="https://"] {
    background: url(../../images/external-link-icon.png) center right no-repeat;
    padding-right: 13px;
  }

  a:hover {
    text-decoration: underline;
  }

  .menuLinkList {
    margin-top: 32px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}
