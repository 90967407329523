// MB brand colors
$orange: #E85815;
$softPink: #FFE3D9;
$darkTeal: #29545F;
$grey1: #F8F9FA;
$grey2: #EFF1F3;
$grey3: #D5D9DE;
$grey4: #A0A5AB;
$grey5: #696C74;
$grey6: #3B3F45;
$charcoal: #2D2D2D;