@use "../../styles/colors";
@use "../../styles/status";

.userDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 100%;
  margin: 2px;
  padding-right: 24px;
  cursor: pointer;

  svg {
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 16px;
    margin-left: 8px;
  }
}

.name {
  min-width: 200px;
}

.status {
  min-width: 100px;
}

.selectedUser {
  border-left: 6px solid colors.$darkTeal;
  padding-left: 10px;
}

.statusDot {
  @include status.dot8;
}
