.subjectLinesContainer {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding: 10px;
    overflow-y: auto;
}

.diversityCard {
    max-width: 800px;
}

.subjectLineSummaryList {
    margin-bottom: 20px;
}

.radioFieldset {
    display: flex;
    flex-direction: row;
    max-width: 700px;
    padding-top: 10px;
}

.radioFieldset input[type="radio"] {
    margin-top: -1px;
    vertical-align: middle;
}

.radioFieldset label {
    margin-bottom: 5px;
    margin-right: 40px;
}

.validationError {
    margin-top: 20px;
}

.submitButtonContainer {
    margin-top: 20px;
}