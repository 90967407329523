.listPane {
  margin: 16px 32px 0 0;
  width: 375px;

  &.scroll {
    overflow-y: auto;
  }

  .listInner {
    padding-right: 8px;
  }
}
