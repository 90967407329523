@use "../../styles/colors";

.personIcon {
  border-radius: 50%;
  background-color: colors.$grey2;
  color: #000;
  text-align: center;
  margin-top: 1px;
  margin-right: 8px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &.size32 {
    height: 32px;
    width: 32px;
    min-width: 32px;

    span {
      line-height: 14px;
      font-size: 14px;
      margin-top: 9px;
    }
  }

  &.size48 {
    height: 48px;
    width: 48px;
    min-width: 48px;
    margin-right: 16px;

    span {
      line-height: 21px;
      font-size: 21px;
      margin-top: 13px;
    }
  }

  span {
    display: inline-block;
  }
}
