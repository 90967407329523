.signinInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  .signedInMessage {
    margin-right: 16px;
  }

}


.linkButton {
  text-decoration: none;
}