@use "../common/styles/colors";

.app {
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: colors.$grey1;
}

.mainContainer {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    overflow-x: hidden;
}

.loadingMessage {
    margin: 20px;
    width: 700px;
}
