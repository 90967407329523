$poorColor: #D10F0F;
$fairColor: #FFC100;
$goodColor: #90EE90;
$veryGoodColor: #228b22;
$excellentColor: #006400;

@mixin dot8 {
  width: 8px;
  min-width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;

  &.highRisk {
    background-color: $poorColor;
  }

  &.mediumRisk {
    background-color: $fairColor;
  }

  &.lowRisk {
    background-color: $excellentColor;
  }

  &.poor {
    background-color: $poorColor;
  }

  &.fair {
    background-color: $fairColor;
  }

  &.good {
    background-color: $goodColor;
  }

  &.veryGood {
    background-color: $veryGoodColor;
  }

  &.excellent {
    background-color: $excellentColor;
  }
}

@mixin statusBorder {
  &.highRisk {
    border-color: $poorColor;
  }

  &.mediumRisk {
    border-color: $fairColor;
  }

  &.lowRisk {
    border-color: $excellentColor;
  }

  &.poor {
    border-color: $poorColor;
  }

  &.fair {
    border-color: $fairColor;
  }

  &.good {
    border-color: $goodColor;
  }

  &.veryGood {
    border-color: $veryGoodColor;
  }

  &.excellent {
    border-color: $excellentColor;
  }

}