.headerBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 72px;
  padding: 0 24px 0 8px;
  background-color: #fff;
  box-shadow: 0 4px 23px rgba(0, 0, 0, 0.12);
}

.title {
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 30px;
  min-width: 192px;
}

.myAccount {
  margin-left: auto;
  text-align: right;
}

.additionalContent {
  margin-left: 24px;
}
