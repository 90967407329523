@use "../../common/styles/colors";

.testSetPicker {
    padding: 24px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: colors.$grey1;
}
.testSetIdCard {
    width: 800px;
}

h3.testSetIdHeading {
    margin-bottom: 24px;
}

.testSetIdContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.testSetIdButtonsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

button.submitTestSetId {
    margin-top: 24px;
    margin-right: 16px;
}

.testSetIdButton {
    width: 400px;
    margin-bottom: 24px;
}

.message {
    color: red;
}