.subjectLineCard {
    max-width: 800px;
    margin-bottom: 20px;
}

.subjectLine {
    font-weight: bold;
    margin-bottom: 10px;
}

.ratingsContainer {
    display: flex;
    flex-direction: column;
}

.radioFieldset {
    display: flex;
    flex-direction: row;
    max-width: 700px;
    padding-top: 10px;
}

.radioFieldset input[type="radio"] {
    margin-top: -1px;
    vertical-align: middle;
}

.radioFieldset label {
    margin-bottom: 5px;
    margin-right: 40px;
}

.problemsFieldset {
    margin-top: 10px;
    padding-top: 10px;
    max-width: 700px;
}

.problemContainer {
    position: relative;
    box-sizing: border-box;
}

.problem {
    width: 50%;
    float: left;
    margin-bottom: 5px;
}

.problem.other {
    width: 100%;
}

.validationError {
    margin-top: 20px;
    color: #D10F0F;
}