@use "../../styles/colors";

.leftNav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;
  padding: 32px 24px 16px 8px;
  margin-left: 0;
  background-color: colors.$darkTeal;
  color: colors.$grey1;

  .navItemsContainer {
    flex-grow: 1;
  }

  .leftNavItem {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    &.selected {
      font-weight: 600;
      border-left: 5px solid colors.$grey1;
      padding-left: 19px;
    }

    &:not(.selected) {
      margin-left: 24px;
    }

    a.leftNavLink {
      span {
        color: colors.$grey1;
      }
    }
  }

  .footer {
    margin-top: auto;
    font-size: 12px;
    text-align: center;
    width: 100%;

    div {
      margin-top: 4px;
    }

    .mbLogoContainer {
      margin-bottom: 16px;
      text-align: center;
      width: 100%;
    }

    a {
      color: colors.$grey1;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

