.eventTime {
	display: none !important;
}


.timegridSlot {
	height: 2vw !important;
}



.heading {
	text-align: center;
	color: #2C3E50;
	margin-top: 1vw;
}
.event {
    display: block;
    opacity: 0.7;
}
.text1 {
    font-family: Spartan, sans-serif;
    font-size: 0.8vw;
    letter-spacing: 0.025vw;
    font-weight: 500;
}
.text2 {
    font-family: Optima, sans-serif;
    font-style: oblique;
    font-size: 1.25vw;
}
.blocked {
    @extend .text1, .event;
    background-color: #E5E5F7;
    border-color: #E5E5F7;
    opacity: 0.8;
    background-size: 0.4vw 0.4vw;
    background-image: repeating-linear-gradient(45deg, #D3D3D3 0, #D3D3D3 1px, #F1F1F1 0, #F1F1F1 50%);
}
.allocated {
    @extend .text2, .event;
    background-color: #67C7FE;
    border-color: #67C7FE;
}
.rank1 {
    @extend .text2, .event;
    background-color: #6CC7FE;
    border-color: #6CC7FE;
}
.rank2 {
    @extend .text2, .event;
    background-color: #8ACFFE;
    border-color: #8ACFFE;
}
.rank3 {
    @extend .text2, .event;
    background-color: #AFDFFE;
    border-color: #AFDFFE;
}
.rank4 {
    @extend .text2, .event;
    background-color: #C7E8FE;
    border-color: #C7E8FE;
}
.existing {
    @extend .text1, .event;
    background-color: #FEBF57;
    border-color: #FEBF57;
}

.modal {
    display: none;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}
  
.modalContent {
    background-color: #F2F2F2;
    float: right;
    padding: 1em;
    border: 1px solid #888;
    width: 25%;
    height: 100%;
}

.close {
    float: right;
    font-size: 28px;
    font-weight: bold;
}
  
.close:hover,
.close:focus {
    color: #aaa;
    text-decoration: none;
    cursor: pointer;
}

.modalHeading {
    font-size: 2em;
    float: left;
    margin: 0.3em;
}

.form {
    position: relative;
    clear: both;
    flex-direction: column;
    left: 4%;
}

.formLabel {
    width: 90%;
    font-size: 1.6em;
    margin: 0.5em 0;
}

.formInput {
    padding: 0.4em;
    margin: 0.1em;
    width: 90%;
    size: 2em;
    font-size: 1.3em;
}

.button {
    background-color: #2C3E50; 
    color: white; 
    transition: 0.3s;
    cursor: pointer;
    text-align: center;
    padding: 7px 16px;
    display: inline-block;
    font-size: 1.3em;
    margin-bottom: 0.8em;
    width: fit-content;
}
  
.button:hover {
    background-color: #1e2b37;
    color: white;
}

.buttonNewBook {
    @extend .button;
}

.buttonBook {
    @extend .button;
    position: relative;
    left: 33%;
}

hr {
    width: 90%;
}

.card {
    cursor: pointer;
    border-style: solid;
    border-color: black;
    border-radius: 0.5em;
    border-width: 0.1em;
    background-color: #FFFFFF;
    margin: 1vh 0;
    padding: 1em;
}

.card:hover {
    transition: 0.1s;
    background-color: #e2e2e5;
}

.cardList {
    display: none;
    position: relative;
    margin: 5%;
    font-size: 1.2em;
}