.pageHeaderContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.headerInfoContainer {
    width: 700px;
}

.instructionsModalContainer {
    margin: 20px;
}

.instructionsButtonContainer {
    margin-left: 30px;
    margin-bottom: 20px;
}