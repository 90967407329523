@use "../../styles/colors";
@use "../../styles/status";

.header {
  display: flex;
  flex-direction: row;
  height: 56px;
  margin-bottom: 2px;
  align-items: center;

  svg {
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 16px;
  }

  .name {
    font-size: 24px;
    font-weight: 600;
  }

  .statusContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #000000;
    padding: 8px 16px;
    height: 32px;
    border-radius: 16px;

    @include status.statusBorder;

    .statusDot {
      @include status.dot8;
    }
  }
}