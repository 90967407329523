@use "../../styles/colors";
@use "../../styles/status";

.content {
  margin-left: 64px;

  h3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .factorsList {
    margin-bottom: 32px;
    padding-left: 16px;

    li {
      list-style-type: none;
      margin-bottom: 16px;
    }

    li:before {
      content: '\2022';
      margin-right: 8px;
    }
  }

  .suggestion {
    margin-bottom: 32px;
    padding-left: 16px;
  }
}
